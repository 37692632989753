/* PhotoFrameMany defines first the overall frame including captions (PhotoFrame), then imageFrame which defines the image size */
/* PhotoFrameMany is the same as PhotoFrame except this frame contains many scrollable photos */
.galleryContainer {
  width: 100%;
  height: auto;
}
.galleryText {
  color: black;
  width: 85%;
  height: auto;
  text-align: left;
  margin: 15px auto 20px auto;
}
.photoFrameManyContainer1 {
  padding: 0px;
}
.photoFrameMany {
    width: 100%;
    height: auto;
    margin-top: 0;
    padding: 30px;
}

.imageFrameMany {
    display: block;
    width: 100%;
    height: auto;
    /* padding: 10px 25px 10px 25px; */
    border-radius: 15px;
}

.captionFrameMany {
    /* color: white; */
    /* padding: 10px 25px 0px 25px; */
}
.captionFrameManyTitle {
    font-weight:bolder;
    font-size: 1.2em;
    padding-top: 10px;
}
[data-fontisblack='false'] {
  /* color: white;  */
}
[data-fontisblack='true'] {
  /* color: black;  */
}
/* ---- below is from swiper example ---- */
.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    /* background: #fff; */
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* border-radius: 15px; */
  }

  /* Change the color of navigation buttons on both sides of the frame */
  /* .swiper-button-prev, .swiper-button-next {
    color: rgb(187, 25, 25); 
    opacity: 0.5;
    font-size:1em;
  } */

  .swiper-button-prev {
    margin-left: 18px;
  }

  .swiper-button-next {
    margin-right: 18px;
  }

  /* change the color of the pagination bullets both passive and active */
  /* change the size of the bullet and active color */
  .swiper-pagination-bullet {
    background-color: rgb(250, 250, 252);
    /* display: inline-block;
    width: 2rem;
    height: 2rem; */
  }
