/* this file defines the contact us ccs attributes */
.contactUsBox {
    padding-top: 40px;
    padding-bottom: 55px;
    /* height: 350px; */
    background-color: white;
    border-top: solid  rgb(162, 206, 243);
    /* background-color: rgba(235, 243, 246, 0.9); */
    margin-top: 100px;
}

.lineColor {
    border-color: rgb(162, 206, 243);
    padding-left: 30px;
}

.titleFrame {
    padding-left: 30px;
    color: rgb(208, 121, 8);
}

.contactUsFrame {
    width: 100%;
    /* padding: 35px; */
    color: rgb(1, 18, 24);
}
.tableContainer {
    width: 85%;
    margin: 25px auto 30px auto;
    background-color: white;
}
.tableCol1 {
    width: 25%;
    padding-right: 25px;
    text-align: right;
    font-weight: bold;
}

.tableCol2 {
    width: 75%;
    padding-right: 25px;
    text-align: left;
}
.mapFrame {
    width:100%;
    box-shadow: 10px 10px 15px rgb(206, 204, 204);
    margin: 15px 15px 15px 0px;
    display: none;
    transition: max-height 0.3s ease;
}
.mapFrame.open {
    display: block;
    max-height: 500px; /* Adjust the height as needed */
    transition: max-height 0.3s ease;
}
.icons {
    margin-right: 10px;
    width: 30px;
    height: 30px;
    /* background-color: white; */
}
.enquiryForm {
    width: 100%;
    display: none;
    transition: max-height 0.3s ease;
}
.enquiryForm.open {
    width: 100%;
    display: block;
    transition: max-height 0.3s ease;
}
.formRowTitle {
    font-size: medium;
}
.formRow2Cols {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
}
.formCol1, .formCol2 {
    width: 50%;
}
.formCol1 {
    padding-right: 15px;
}
.formCol2 {
    padding-left: 15px;
}
.enquiryForm input {
    padding-left: 10px;
}

.enquiryForm p {
    margin-bottom: 0px;
}
.inputBox {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border-width: 1px;
    background-color: rgb(239, 234, 234);
    /* padding-left: 3px!important; */
}
td textarea {
    min-height: 200px;
    background-color: rgb(239, 234, 234);
    resize: vertical;
}
.submitContainer {
    display: flex;
    align-items: center;
    justify-content: right;
    margin: 10px 0px 20px auto;
}
.submitButton {
    text-align: center;
    color: white;
    width: 25%;
    height: 40px;
    border-radius: 5px;
    border-width: 1px;
    background-color: rgb(175, 130, 71);
    padding-top: 5px;
}
.submitButton:hover {
    background-color: rgb(132, 95, 47);
}

/* Below block defines the dropdown box for subject selecttion */
.subjectDropdownBox {
    display: flex;
    align-items: center;
    justify-content: left;
    height: 40px;
    border-radius: 5px;
    border-width: 1px;
    background-color: rgb(235, 219, 202);
}
.subjectBoxLeft {
    width: 80%;
    padding-left: 10px;
    font-size: medium;
}
.subjectBoxRight {
    width: 20%;
    text-align: center;
    font-size: 1.8em;
    color: rgb(84, 85, 153);
}
.subjectDropdownList, #subjectDropdownList {
    width: 20em;
    display: none;
    height: 250px;
    opacity: 1;
    position: absolute;
    padding-left: 10px;
    background-color: white;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}
.subjectItem {
    height: 40px;
    padding-left: 5px;
}
.subjectItem:hover {
    background-color: lightgray;
}
#submittedMsgBox {
    display: none;
    color: green;
    text-align: left;
    height: 40px;
    width: 100%;
}

