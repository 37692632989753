.viewPortSelectRoomType {
    display: block;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    color: black;
}
.selectRoomTypeTitle {
    margin: 20px auto 20px auto;
    color: black;
}
.availableTypesFrame {
    display: flex;
    justify-content: left;
    text-align: left;
    width: 70%;
    margin: auto;
    flex-wrap: wrap;
    /* background-color: green; */
    /* margin-left: 110px; */
}
/* For rendering small screen */
@media only screen and (max-width: 760px) {
    .availableTypesFrame {
        width: 90%;
    }
}