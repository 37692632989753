.viewPortSummary {
    width: 100%;
    display: block;
    height: auto;
}
.summaryTitle {
    margin: 20px auto 20px auto;
    color: black;
}
.priceSummaryBox {
    width: 60%;
    background-color: white;
    border-radius: 6px;
    margin: auto;
}
.summaryLine {
    display: flex;
    width: 100%;
    align-items: center;
    line-height: 10px;
}
.summaryTextLeft {
    width: 70%;
    text-align: left;
    padding: 0px 25px 0px 25px;
}
.summaryTextRight {
    width: 30%;
    text-align: right;
    padding: 0px 25px 0px 25px;
}

.continueButtonBox {
    display: flex;
    justify-content: right;
    margin: 10px 20% 5px auto;
}
.continueButton {
    width: 250px;
    height: 50px;
    cursor: pointer;
    color: white;
    background-color: rgb(160, 108, 11);
    padding: 10px;
    border-radius: 5px;
    white-space: nowrap;
}
.continueButton:hover{
    background-color: rgb(113, 76, 6);
}

/* For rendering small screen */
@media only screen and (max-width: 768px) {
    .priceSummaryBox {
        width: 90%;
    }
    .continueButton {
        margin-right: 5%;
    }
}