/*
Uses similar mechnism as in header. In large screens, as one scrolls down, an arrow box can lead to the top of the page again.
For small screen, booking button on top disappears, and mvoes to the bottom right.
*/
/* For large screens */
@media only screen and (min-width: 768px) {
    .footer {
        position: fixed;
        height: 90px;
        width: 90px;
        bottom: 0;
        right:0;
        z-index: 1;
        margin: 10px;
        transition: .3s ease-in;
        overflow: hidden!;
    }

    .footer-bg {
        background-color: white;
        /* background-color: rgba(153, 213, 213, 0.9); */
        border-radius: 5px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .footer-bg:hover {
        background-color: rgba(6, 122, 122, 0.9);
    }

    /* (.footer::after{} clears settings for elements that come after. Not needed since elements in footer occupy full row */
    .footer::after {
        content: "";
        clear: both;
        display: table;
    }

    /* display: block makes the whole area clickable as specified by w and h */
    .bottomBigScreen {
        display: flex;
        width: 90px;
        height: 90px;
        padding: 15px;
        font-size: 40px;
        align-items: center;
        justify-content: center;
        /* text-align: center;  */
        z-index:1;
    }

    #fcUp {
        font-size: 40px;
        opacity: 0.0;
    }

    /* Hide the small screen vars */
    .bottomSmallScreen1, .bottomSmallScreen2, .bookingBottom, .botthomMenuBar, .langDropdownBoxFooter {
        display: none;
    }

}

/* For small screens */
@media only screen and (max-width: 768px) {
    .footer {
        position: fixed;
        height: 60px;
        width: 100%;
        bottom: 0;
        z-index: 1;
        transition: .3s ease-in;
        /* overflow: auto!;  /* originally hidden, and it cuts off dropdown list. Now ok */
    }

    .footer-bg {
        background-color: white;
        /* background-color: rgba(153, 213, 213, 0.9); */
    }

    /* (.footer::after{} clears settings for elements that come after. Not needed since elements in footer occupy full row */
    .footer::after {
        content: "";
        clear: both;
        display: table;
    }

    /* The following elements present bottom menu for small screens */
    .botthomMenuBar {
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
    }
    .bottomSmallScreen1, .bottomSmallScreen2, .bookingBottom {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        height: 60px;
        width: 33%;
        text-align: center;
    }
    .bottomSmallScreen1 {
        background-color: white;
        color: black;
    }
    .bottomSmallScreen2 {
        background-color: rgb(70, 142, 124);
        color: white;
    }

    .bottomSmallScreen1:hover {
        background-color: rgb(225, 204, 204);
    }
    .bottomSmallScreen2:hover {
        background-color: rgb(27, 69, 58);
    }
    .bookingBottom {
        background-color: rgb(173, 127, 9);
        color: white;
    }
    .bookingBottom:hover {
        background-color: rgb(97, 73, 15);
    }

    .bottomBigScreen {
        display: none;
    }

    /* .langDropdownBoxFooter {
        width: 100%;
        background-color: white;
        font-size:medium;
        text-align: center;
        padding: 10px 5px 10px 5px;
        white-space:nowrap;
        color:black;
        background-color: rgb(236, 220, 220);
    } */
    .langDropdownItemsFooter {
        display: none;
        position: absolute;
        min-width: 200px;
        bottom: 50px;
        left: 15px;
        /* min-height: 200px; */
        z-index: 10!important;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        background-color: white;
        color: black;
        text-align: left;
        margin: 10px;
        cursor: pointer;
    }

    /* Upon hovering over language box */
    .bottomSmallScreen1:hover > .langDropdownItemsFooter {
        display: block;
    }

    .langDropdownItemFooter {
        margin: 10px auto 5px 10px;
        /* height: 70px; */
        width: 80%;
        color: black;
        background-color: white;
    }
    .langDropdownItemFooter:hover {
        background-color: rgb(224, 230, 219);
    }

    .langButton {
        width: 60px;
        height: 60px;
        background-color: transparent;
        border: none;
    }

    #langIcon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        /* object-fit: cover; */
        /* align-items: center;    */
        /* background-color: white; */
    }

}
