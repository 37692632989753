/* This is the css for gallery including the base frame and menu bar */
body {
    /* background-color: white; */
    /* background-color: #00709e; rgb(1, 114, 159); */
}
.galleryHeaderFrame {
    margin-top: 90px;
    width: 100%;
    height: auto;
    text-align: center;
    padding-top: 30px;
    background-color: white;
    /* background-color: rgba(235, 243, 246, 0.9); */
}

.title {
    color: rgb(27, 54, 105)!important;
}

/* The css feature used here is called flexbox. See w3c/css/advanced/flexbox */
.galleryMenu {
    display: flex;              /* this has to be flex, or else below two commands do not work */
    justify-content: center;    /* vs align-items: center; which puts contents center vertically */
    flex-wrap: wrap;            /* this wraps the menu items as view port gets narrow */ 
    width: 100%;
    height: 40px;
    margin: 25px auto 15px auto;
    border-bottom: solid rgb(123, 147, 190);
}

.menuItem {
    /* float: left; */
    color: rgb(27, 54, 105);
    font-size: 20px;
    font-weight: bold;
    padding-right: 15px;
    /* border-bottom: solid rgb(123, 147, 190); */
}

.menuItem:hover {
    color: rgb(62, 108, 193);
    border-bottom: solid 0.2em rgb(123, 147, 190)!important;
}

.menuItem:focus { /* This makes the link highlighted after click */
    color: rgb(62, 108, 193);
    border-bottom: solid 0.2em rgb(123, 147, 190)!important;
}

.dividerLine {
    border-top: solid rgb(27, 54, 105);
    margin-top: -15px;
}
