.photoRollContainer {
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
}

.imageRollFrame {
    /* display: block; */
    width: 100%;
    height: auto;
    border-radius: 0;
}
.swiper-slide img {
    /* display: block; */
    width: 100%;
    height: 100%;
    object-fit: cover;
  }