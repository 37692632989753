.react-calendar {
    width: 600px;
    max-width: 100%;
    background-color: #fff;
    color: #222;
    border-radius: 6px;
    /* box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2); */
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    border-left: 1px solid rgb(240, 226, 226);
  }
   
  .react-calendar__navigation button {
    color: #d4a713;
    min-width: 44px;
    background: none;
    font-size: 16px;
    margin-top: 8px;
  }
  button.react-calendar__navigation__label {
    color: black;
    text-align: center;
    font-size: 1.2em;
    display: flex;
    justify-content: center;
    pointer-events: none;
  }
   
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #f8f8fa;
  }

  /* Hide the year navigation buttons */
  .react-calendar__navigation__next2-button,  .react-calendar__navigation__prev2-button{
    display: none;
  }
  .react-calendar__navigation__arrow {
    padding-right: 15px;
    font-size: 2em;
  }
  .react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
  }
  abbr[title] {
    text-decoration: none;
  }
  abbr {
    font-size: 15px!important;
  }
   
  /* .react-calendar__month-view__days__day--weekend {
    color: #d10000;
   } */
   
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background: #f8f8fa;
    color: #d4a713;
    border-radius: 6px;
  }
   
  .react-calendar__tile--now {
    background: #d4a71333;
    border-radius: 6px;
    font-weight: bold;
    color: #d4a713;
  }
   
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #d4a71333;
    border-radius: 6px;
    font-weight: bold;
    color: #d4a713;
  }
   
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #f8f8fa;
  }
   
  .react-calendar__tile--active {
    background: #d4a713;
    border-radius: 6px;
    font-weight: bold;
    color: white;
  }
   
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #d4a713;
    color: white;
  }
   
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #f8f8fa;
  }
   
  .react-calendar__tile--range {
    background: #f8f8fa;
    color: #d4a713;
    border-radius: 0;
  }
   
  .react-calendar__tile--rangeStart {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background: #d4a713;
    color: white;
  }
   
  .react-calendar__tile--rangeEnd {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background: #d4a713;
    color: white;
  }