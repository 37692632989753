.viewPortSelectRates {
    width: 100%;
    display: block;
    height: auto;
}
.selectRatesTitle {
    margin: 20px auto 20px auto;
    color: black;
}
.selectRatesBox{
    display: flex;
    width: 70%;
    margin: auto;
}
.selectRatesBoxLeftCol, .selectRatesBoxRightCol {
    width: 50%;
}
.ratesTitle {
    width: 100%;
    text-align: center;
}
.ratesBox, .instructionBox {
    width: 97%;
    border-radius: 6px;
    background-color: white;
    text-align: left;
    padding: 25px;
    line-height: 15px;
}
.instructionBox {
    margin-left: 4%;
}
.oneRateBox {
    display: flex;
    align-items: flex-end;
}
.oneRateBoxText {
    width: 67%;
}
.oneRateBoxButton {
    width: 30%;
    height: 35px;
    text-align: center;
    background-color: rgba(149, 99, 7, 0.554);
    color: white;
    border-radius: 5px;
    margin-bottom: 10px;
    padding-top: 10px;
}
.oneRateBoxButton:hover {
    background-color: rgba(83, 56, 7, 0.554);
}
.instructionBox {
    line-height: 25px;
}
textarea {
    resize: none;
}
#instructionText {
    width: 99%;
    height: 300px;
    padding: 10px;
}
.whiteSpace {
    width: 100%;
    height: 50px;
}

/* For rendering small screen */
@media only screen and (max-width: 768px) {
    .selectRatesBox{
        display: block;
        width: 90%;
        margin: auto;
    }
    .selectRatesBoxLeftCol, .selectRatesBoxRightCol {
        width: 100%;
    }
    .instructionBox {
        margin-left: 0;
    }
}