.bookingInfoFrame{
    display: flex;
    width: 70%;
    height: auto;
    margin: 0px auto 0px auto;
    background-color: white;
    border-radius: 6px;
}
.selectedDates {
    width: 43%;
    margin: 15px;
    display: block;
}
.selectedDatesTitle {
    text-align: left;
    margin: 5px;
    font-weight: bold;
}
.selectedDatesInfo {
    text-align: left;
    margin: 5px;
}
.selectedGuestNums {
    width: 43%;
    margin: 15px;
    display: block;

}
.selectedGuestNumsTitle {
    text-align: left;
    margin: 5px;
    font-weight: bold;

}
.selectedGuestNumsInfo {
    text-align: left;
    margin: 5px;
}
.bookingInfoEditButton{
    width: 14%;
    margin: auto 15px auto auto;
    height: 30px;
    text-align: center;
    color: black;
    border-radius: 5px;
    background-color: rgb(200, 195, 195);;
}
/* For rendering small screen */
@media only screen and (max-width: 768px) {
    .bookingInfoFrame{
        width: 90%;
    }
}