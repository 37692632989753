body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'lucinda handwriting', cursive, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  line-height: 22pt;
  font-size: medium;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #00709e; /* rgb(1, 114, 159); */
  z-index: -2; /* <body> is the lowest layer. On top of it is <div mainFeature with z-index of -1, then header/footer 0 */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
